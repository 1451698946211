import { useEffect, useMemo, useState } from "react";

import { EmergencyRoleIdReplacement } from "../../entity/emergencyRoleIdReplacement";
import { request } from "../../libs/api";

import { useMonitorGetAllRolesQuery } from "./queries";

export const useEmergencyPrintBackup = () => {
  const [emergencyRoleIdReplacement, setEmergencyRoleIdReplacement] = useState(
    [] as EmergencyRoleIdReplacement[],
  );

  const { data: roleData } = useMonitorGetAllRolesQuery();
  const roles = useMemo(() => roleData?.role ?? [], [roleData?.role]);

  useEffect(() => {
    request.get("emergency_role_replacement/all").then((ret) => {
      setEmergencyRoleIdReplacement(ret.data);
    });
  }, []);

  const refetch = async () => {
    const ret = await request.get("emergency_role_replacement/all");
    setEmergencyRoleIdReplacement(ret.data);
  };

  return { emergencyRoleIdReplacement, roles, refetch };
};
